import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Button, Table } from "react-bootstrap";
import { FaRegEye, FaHandPointUp } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Tenderimg from "../images/tenderimg.jpg";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import axios from "axios";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
const Tender = () => {
  const [tenders, setTenders] = useState([]);

  useEffect(() => {
    const fetchPublishedTenders = async () => {
      try {
        const response = await axios.get(
          "https://api.lissomtech.in/api/tenders/published"
        );
        setTenders(response.data);
      } catch (error) {
        console.error("Error fetching published tenders:", error);
      }
    };

    fetchPublishedTenders();
  }, []);

  const openGoogle = () => {
    window.open("https://poetic-tiramisu-c6bbf6.netlify.app/", "_blank");
  };

  const imageStyles = {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    marginTop: "100px",
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return format(date, "dd/MM/yyyy", { locale: enGB });
  };

  return (
    <div>
      <Col sm={12}>
        <Image src={Tenderimg} style={imageStyles} fluid />{" "}
      </Col>
     
          <Card.Title style={{marginTop:'40px'}}>
            <h2>Tenders</h2>
          </Card.Title>
          <br />

          <Table striped bordered hover responsive className="table-responsive">
            <thead className="theadt" style={{ textAlign: "center" }}>
              <tr>
                <th>Tender_Number</th>
                <th style={{ maxWidth: "450px", whiteSpace: "pre-wrap" }}>
                  Bill_To
                </th>
                <th style={{ maxWidth: "450px", whiteSpace: "pre-wrap" }}>
                  Ship_To
                </th>
                <th>Sales_Agent</th>
                <th>Status</th>
                <th> Publish_Date</th>
                <th> Expiry_Date</th>
                <th>Total_Amount</th>
                <th>View</th>
                <th>Apply</th>
              </tr>
            </thead>
            <tbody className="tbodyt" style={{ textAlign: "center" }}>
              {Array.isArray(tenders) && tenders.length > 0 ? (
                tenders.map((item) => (
                  <tr key={item.id}>
                    <td>{item.TenderNo}</td>
                    <td style={{ maxWidth: "450px", whiteSpace: "pre-wrap" }}>
                      {item.Bill_To}
                    </td>
                    <td style={{ maxWidth: "450px", whiteSpace: "pre-wrap" }}>
                      {item.Ship_To}
                    </td>
                    <td>{item.Sales_Agent}</td>
                    <td>{item.Status}</td>
                    <td>{formatDate(item.TenderDate)}</td>
                    <td>{formatDate(item.Expiry_Date)}</td>
                    <td>{item.TenderTotalAmont}</td>
                    <td>
                      <Link className="learn-more-link">
                        <button
                          className="btn btn-outline-dark"
                          style={{ border: "none", fontSize: "20px" }}
                        >
                          <FaRegEye />
                        </button>
                      </Link>
                    </td>

                    <td>
                      <Link className="learn-more-link" >
                        <button
                          className="btn btn-outline-dark"
                          style={{ border: "none", fontSize: "20px" }}
                        >
                          <FaHandPointUp />
                        </button>
                      </Link>
                      {" "}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No tenders found.</td>
                </tr>
              )}
            </tbody>
          </Table>
       
      <Footer />
    </div>
  );
};

export default Tender;
