import Logo from "../images/Logo.png";
import { BsList } from "react-icons/bs";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PiListThin } from "react-icons/pi";
import "../css/Navbar.css";
import Sidebar from "./Sidebar";
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";
const Navbars = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenn, setSidebarOpenn] = useState(false);

  const handleNavItemClick = () => {
    setSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const toggleSidebarr = () => {
    setSidebarOpenn(!sidebarOpenn);
  };

  const [logoData, setLogoData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.matoshreeinteriors.in/api/getlogos")
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setLogoData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div style={{ padding: "0px" }}>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top custom-navbar"
        style={{ padding: "0px" }}
      >
        <div className="container" style={{ padding: "0px" }}>
          {logoData.map((data, index) => (
            <NavLink key={index}>
              <img
                src={`https://api.matoshreeinteriors.in/${data.logo}`}
                alt="Header Logo"
                width="180"
                height="100"
                claNavLinkssName="d-inline-block align-top"
              />
            </NavLink>
          ))}

          {/* <NavLink className="navbar-brand" to="/">
            <img
              src={Logo}
              alt="Left Image"
              width="180"
              height="100"
              className="d-inline-block align-top"
            />
          </NavLink> */}

          <NavLink className="navbar-toggler abc" onClick={toggleSidebar}>
            <BsList
              size="1.3em"
              className="mylinktag1"
              style={{ padding: "0px", margin: "0px" }}
            />
          </NavLink>
          <div
            className={`collapse navbar-collapse custom-collapse ${
              sidebarOpen ? "show" : ""
            }`}
          >
            <ul className="navbar-nav mr-auto mynav">
              <li>
                <NavLink
                  to="/"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutus"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  ABOUT US
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  SERVICES WE OFFER
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/gallery"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  GALLERY
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/projects"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  PROJECTS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/career"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  CAREERS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/events"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  EVENTS
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/tender"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  TENDER
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/contactus"
                  className="mylinktag"
                  onClick={handleNavItemClick}
                >
                  CONTACT US
                </NavLink>
              </li>

              <li>
                <NavLink onClick={toggleSidebarr}>
                  <PiListThin size="1.7em" className="mylinktag1" />
                  <Sidebar
                    isOpen={sidebarOpenn}
                    toggleSidebarr={toggleSidebarr}
                  />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbars;
