import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Form } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import ev from "../images/ev.jpg";
import ev1 from "../images/ev1.jpg";
import ev2 from "../images/ev2.jpg";
import ev3 from "../images/ev3.jpg";
import ev4 from "../images/ev4.jpg";
import ev5 from "../images/ev5.jpg";
import ev6 from "../images/ev6.jpg";
import ev7 from "../images/ev7.jpeg";
import ev8 from "../images/ev8.jpg";
import ev9 from "../images/ev9.jpeg";
import Footer from "../components/Footer";
import Image from "react-bootstrap/Image";
import axios from "axios";
// import ApiConfig from './ApiConfig';
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
const Events = () => {
  const cardStyle = {
    width: "100%",
    marginBottom: "40px",
    height: "450px",
  };

  const imgStyle = {
    height: "200px",
    objectFit: "cover",
    marginTop: "10px",
  };

  const imageStyles = {
    width: "100%",
    height: "500px",
    objectFit: "cover",
    marginTop: "100px",
  };

  const eventtext = {
    fontFamily: "Montseerrat",
    fontWeight: "400",
    fontSize: "40px",
    lineHeight: "1.7",
    color: "rgb(15, 15, 15)",
    margin: "0px",
    textAlign: "center",
    marginTop: "20px",
  };

  const [eventData, setEventData] = useState([]);
  // const apiUrl = ApiConfig.apiUrl
  useEffect(() => {
    // Fetch data from the API endpoint
    axios
      .get("https://api.matoshreeinteriors.in/api/events")
      // axios.get(`${apiUrl}/api/events`)
      //axios.get('https://matoshreewebsite.lissomtech.in/APIMatoshree/api/events')
      .then((response) => {
        // Log the fetched data to the console
        console.log("Fetched Data:", response.data);

        // Set the fetched data to the state
        setEventData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [eventheadData, setEventHeadData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.matoshreeinteriors.in/api/get_event_header")

      .then((response) => {
        console.log("Fetched Data:", response.data);
        setEventHeadData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handlePublishToggle = (eventId, checked) => {
    console.log(`EventID: ${eventId} - Published: ${checked}`);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return format(date, "dd/MM/yyyy", { locale: enGB });
  };

  return (
    <>
      <div className="event-container">
        {/* <Image src={ev} fluid style={imageStyles} /> */}
        {eventheadData.map((data, index) => (
          <Image
            src={`https://api.matoshreeinteriors.in/${data.head_Event}`}
            fluid
            style={imageStyles}
          />
        ))}

        <p style={eventtext}>Events</p>

        <Container>
          <Row>
            {eventData
              .filter((event) => event.Published)
              .map((event, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={4}>
                  <Card style={{ height: "400px", marginTop: "15px" }}>
                    <Card.Img
                      variant="top"
                      src={`https://api.matoshreeinteriors.in/${event.Img}`}
                      alt={`Event Image ${index + 1}`}
                      style={imgStyle}
                    />
                    <Card.Body>
                      <Card.Title>{event.EventName}</Card.Title>
                      <Card.Text>{event.Description}</Card.Text>
                      <Card.Text>Date: {formatDate(event.EventDate)}</Card.Text>
                      <Card.Text>Venue: {event.EventLocation}</Card.Text>
                      {event.Published ? null : (
                        <Form.Check
                          id={`custom-switch-${event.EventID}`}
                          onChange={(e) =>
                            handlePublishToggle(event.EventID, e.target.checked)
                          }
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Events;
