import { Link, Outlet } from "react-router-dom";
import Gallary2png from "../images/Gallary2.png";
import React, { useState, useEffect } from "react";
import { Card, Form, Button, Table } from "react-bootstrap";
// import GalleryCard from "../GalleryCard";
import GalleryCard from "./GalleryCard";
import axios from "axios";
import "../css/Gallery.css";
import Footer from "../components/Footer";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
function Gallary() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [errors, setErrors] = useState({
    image: "",
    categories: "",
  });

  useEffect(() => {
    fetchGalleryData();
    setSelectedCategory("AllPhotos");
  }, []);

  const fetchGalleryData = async () => {
    try {
      const response = await axios.get(
        "https://api.matoshreeinteriors.in/getGalleryData"
      );
      setGalleryData(response.data);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleCategoryChange = (category) => {
    const isSelected = selectedCategories.includes(category);

    if (isSelected) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const validateFormGallery = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!selectedImage) {
      newErrors.image = "Please select an image.";
      valid = false;
    } else {
      newErrors.image = "";
    }

    if (selectedCategories.length === 0) {
      newErrors.categories = "Please select at least one category.";
      valid = false;
    } else {
      newErrors.categories = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    const { image, categories } = galleryData[index];
    setSelectedImage({ name: image });
    setSelectedCategories(categories.split(", "));
  };

  const handleDelete = (index) => {
    console.log(`Deleting item at index ${index}`);
  };

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.matoshreeinteriors.in/projects")
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  const [galleryDatas, setGalleryDatas] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.matoshreeinteriors.in/api/get_header_img")

      .then((response) => {
        console.log("Fetched Data:", response.data);
        setGalleryDatas(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Selected Category:", selectedCategory);
    console.log("Gallery Data:", galleryData);
    fetchGalleryData();
  }, [selectedCategory]);
  return (
    <>
      <div>
        {galleryDatas.map((data, index) => (
          <div className="img-container" key={index}>
            <img
              src={`https://api.matoshreeinteriors.in/${data.head_Gallery}`}
              className="card-img"
              alt="Image 2"
              style={{ height: "400px", marginTop: "100px" }}
            />
          </div>
        ))}
        <br />

        <ul className="nav justify-content-center">
          <li className="nav-item gallary">
            <button
              className={`nav-link ${
                selectedCategory === "AllPhotos" ? "active" : ""
              }`}
              onClick={() => setSelectedCategory("AllPhotos")}
            >
              All Photos
            </button>
          </li>
          <li className="nav-item gallary">
            <button
              className={`nav-link ${
                selectedCategory === "Project" ? "active" : ""
              }`}
              onClick={() => setSelectedCategory("Project")}
            >
              Projects's
            </button>
          </li>
          <li className="nav-item gallary">
            <button
              className={`nav-link ${
                selectedCategory === "Event" ? "active" : ""
              }`}
              onClick={() => setSelectedCategory("Event")}
            >
              Event's
            </button>
          </li>
          <li className="nav-item gallary">
            <button
              className={`nav-link ${
                selectedCategory === "Office" ? "active" : ""
              }`}
              onClick={() => setSelectedCategory("Office")}
            >
              Office
            </button>
          </li>
          <li className="nav-item gallary">
            <button
              className={`nav-link ${
                selectedCategory === "Staff" ? "active" : ""
              }`}
              onClick={() => setSelectedCategory("Staff")}
            >
              Staff
            </button>
          </li>
        </ul>

        <div
          className="container"
          style={{ marginTop: "10px", padding: "0px" }}
        >
          <div className="row">
            {galleryData
              .filter((data) => {
                const trimmedCategories = data.categories
                  .split(",")
                  .map((cat) => cat.trim());

                if (selectedCategory === "AllPhotos") {
                  return true;
                } else {
                  return trimmedCategories.includes(selectedCategory);
                }
              })
              .map((data, index) => (
                <div key={index} className="col-md-3 md-3 mb-3">
                  <GalleryCard
                    image={data.image}
                    categories={data.categories}
                    onEdit={() => handleEdit(index)}
                    onDelete={() => handleDelete(index)}
                  >
                    <img
                      src={data.image}
                      alt={`Image for ${data.categories}`}
                      style={{
                        height: "200px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </GalleryCard>
                </div>
              ))}
          </div>
        </div>

        <br></br>
        <br></br>
        {/* <div className="container" style={{ marginTop: "10px", padding: "0px" }}>
          <br></br>
          <div className="row">
            {projects.map((project, index) => (
              <div key={project.id} className={col-md-4 ${index > 2 ? 'mt-4' : ''}}>

                <div className="card">
                  <img
                    src={data:image/png;base64,${project.image}}
                    className="card-img-top same-height-image"
                    alt={Image for ${project.name}}
                  />

                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
      <Outlet></Outlet>
      <Footer />
    </>
  );
}

export default Gallary;
