import React, { useState } from "react";
import Card from "react-bootstrap/Card";

const UserInfo = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Card
        style={{
          width: "500px",
          marginTop: "250px",
        }}
      >
        <Card.Body>
          <Card.Title>Fill The Below Form</Card.Title>
          <br />
          <form>
            <hr />
            <br />

            <br />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Vendor Name"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="Mobile Number"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="G.S.T Number"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Pancard Number"
                required
              />
            </div>

            <br />
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Udyam Aadhar"
                required
              />
            </div>

            <br />

            <button type="submit" className="btn btn-dark">
              Apply
            </button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserInfo;
