import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api.matoshreeinteriors.in/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        // setIsLoggedIn(true);
        navigate("/userinfo");
      } else {
        setError("Invalid email or password. ");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="login-container">
      <div class="login_container">
        <div class="heading">Log In</div>
        {error && (
          <div className="error-message" style={{ color: "red" }}>
            {error}
          </div>
        )}
        <form action="" class="form" onSubmit={handleLogin}>
          <input
            required=""
            class="input"
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            required=""
            class="input"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span class="forgot-password">
            <a href="#">Forgot Password ?</a>
          </span>
          <input
            class="login-button"
            type="submit"
            value="Log In"
            // onClick={handleLogin}
          />
        </form>

        <span class="agreement">
          <Link to="signup">
            {" "}
            <a>Don't have an account?Sign up</a>
          </Link>
        </span>
      </div>
    </div>
  );
};
Login.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
};
export default Login;
