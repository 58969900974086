import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Signup.css";
import { Link } from "react-router-dom";
import Popup from "../components/Popup";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (formData.firstname.trim() === "") {
      newErrors.firstname = "Name is required";
      valid = false;
    } else {
      newErrors.firstname = "";
    }

    if (formData.lastname.trim() === "") {
      newErrors.lastname = "Name is required";
      valid = false;
    } else {
      newErrors.lastname = "";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = " Email is required";
      valid = false;
    } else {
      newErrors.email = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(
          "https://api.matoshreeinteriors.in/login/userlogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await response.json();

        if (data.success) {
          console.log("Form submitted successfully:", formData);
          // Reset form data
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            message: "",
          });
          openPopup(); // Open the popup
        } else {
          console.error("Form submission failed:", data.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <div className="login-container">
      <Popup
        isOpen={showPopup}
        onRequestClose={closePopup}
        message="Sign In  successfully!"
      />
      <div class="login_container">
        <div class="heading">Sign Up</div>
        <form action="" class="form" onSubmit={handleSubmit}>
          <input
            required=""
            class="input"
            type="text"
            name="firstname"
            id="firstname"
            placeholder="First_Name"
            value={formData.firstname}
            onChange={handleChange}
          />
          <br />

          <input
            required=""
            class="input"
            type="text"
            name="lastname"
            id="lastname"
            placeholder="Last_Name"
            value={formData.lastname}
            onChange={handleChange}
          />

          <br />

          <input
            required=""
            class="input"
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
          />

          <br />

          <input
            required=""
            class="input"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />

          <br />

          <input
            required=""
            class="input"
            type="password"
            name="password"
            id="password"
            placeholder="Confirm_Password"
            value={formData.password}
            onChange={handleChange}
          />

          <br />

          <input class="login-button" type="submit" value="Signup" />
        </form>

        <span class="agreement">
          <Link onClick={() => navigate(-1)}>
            {" "}
            <a>Allready have an account ? Log In</a>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Signup;
