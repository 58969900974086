import React from 'react';

import { Card } from "react-bootstrap";
import Projectvd from '../videos/Projectvd.mp4';
import Projectvd2 from '../videos/Projectvd2.mp4';
import scope from '../subimg/scope.png';
import Design from '../subimg/design.png';
import budget from '../subimg/bugdet.png'
import challenges from '../subimg/challenges.png'
import env from '../subimg/env.png'
import noteworthy from '../subimg/Noteworthy.png'
import quality from '../subimg/quality.png'
import safety from '../subimg/saftey.png'
import timeline from '../subimg/timeline.png'
import update from '../subimg/update.png'
import img1 from '../subimg/img1.png'
import img2 from '../subimg/img2.png'
import img3 from '../subimg/img3.png'
import img5 from '../subimg/img5.png'
import img6 from '../subimg/img6.png'
import img7 from '../subimg/img7.png'
import img8 from '../subimg/img8.png'
import img11 from '../subimg/img11.png'
import img12 from '../subimg/img12.png'
function Lernmore() {
  return (
    
<div>
      <Card className="zigzag-card">
        <div className="row">
          <div className="row g-0">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-body">
                  <h1 className="card-title" style={{ fontSize: "40px" }}>Project Name: Shiri Saibaba Education Complex Shirdi</h1>
                  <h4 className="card-text">Location: Shirdi</h4>

                  <Card className="video-card"  style={{height:"400px",}}>
                    <video autoPlay muted loop controls={false}>
                      <source src={Projectvd} type="video/mp4" style={{height:"200px"}} />
                      Your browser does not support the video tag.
                    </video>
                  </Card>
                  <br></br>
                  <p className="card-text">Project Area: 588060 Sq.ft Construction Of 5 Buildings Junior & Senior College English Medium School Kanya Vidyalaya Auditorium, 4.5 Acre Playground, Sports Complex, Swimming Pool To Be Done Over A Total Plot Area Of 13.5 Acres Main Contractor Nyatt Group, Pune Architect: D. Q. Nikam, Pune.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12" >
            <div className="card mb-3" style={{ maxWidth: "800px" }} >
              <div className="row g-0">
                <div className="col-md-6" style={{ marginTop: "40px" }}>
                  <img src={scope} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
                </div>
                <div className="col-md-6">
                  <div className="card-body">
                    <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Scope of Area</h3>
                    <p className="card-text">Details about the projects scope go here.</p>
                    <p className="card-text">In this project, the defined scope of area is the downtown district of [Shirdi], encompassing the area bounded by [Street A], [Street B], [Street C], and [Street D]. The project area covers approximately 50 acres. The selection of this area is crucial as it is the primary target market for our retail expansion initiative. It is important to note that any proposed changes to the project area boundaries will require approval from the local zoning authority. The success of our project, including revenue projections and market penetration, is directly tied to the dynamics of this specific urban location.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3" style={{ maxWidth: "800px", marginLeft: "500px" }} >
          <div className="row g-0">
            <div className="col-md-6" style={{ marginTop: "90px" }}>
              <img src={noteworthy} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px", width: "500px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Noteworthy Features</h3>
                <p className="card-text">Highlight notable project features.</p>
                <p className="card-text">
                  Our project boasts several noteworthy features that set it apart from the rest. The architectural design blends modern aesthetics with elements inspired by the local culture, creating a unique and visually stunning environment.
                </p>
                <p className="card-text">
                  Sustainability is a core focus, with energy-efficient systems and eco-friendly building materials used throughout. The project has received multiple awards for its commitment to environmental responsibility and innovation.
                </p>
                <p className="card-text">
                  Additionally, our project has a strong community impact, providing jobs, educational opportunities, and cultural enrichment for the local residents. The integration of cutting-edge technology and safety measures ensures a secure and advanced living experience for all.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-12" >
          <div className="row g-0">
            <div className="col-md-6">
              <Card className="video-card" style={{ height: "400px", marginTop: "200px" }} >
                <video autoPlay muted loop controls={false} style={{ marginTop: "30px" }} >
                  <source src={Projectvd2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Card>
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Contractor and Subcontractor</h3>
                <h4 className="card-subtitle mb-2 text-muted">Main Contractor</h4>
                <p className="card-text">Our main contractor for this project is [Main Contractor Name]. They bring extensive experience in managing complex construction projects and have a proven track record of delivering high-quality results.</p>

                <h4 className="card-subtitle mb-2 text-muted">Subcontractors</h4>
                <p className="card-text">In addition to our main contractor, we have enlisted the expertise of several subcontractors who specialize in various aspects of the project. These subcontractors include:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Subcontractor 1 Name]: Responsible for [Subcontractor 1 Role]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Subcontractor 2 Name]: Responsible for [Subcontractor 2 Role]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Subcontractor 3 Name]: Responsible for [Subcontractor 3 Role]</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Roles and Responsibilities</h4>
                <p className="card-text">Our main contractor is responsible for overall project management, including planning, scheduling, and quality control. Subcontractors are tasked with executing specialized aspects of the project in their respective domains. Clear roles and responsibilities have been established to ensure seamless collaboration.</p>

                <h4 className="card-subtitle mb-2 text-muted">Experience and Credentials</h4>
                <p className="card-text">Our main contractor and subcontractors have a wealth of experience in the construction industry. They hold relevant certifications and have successfully completed projects similar in scale and complexity to this one.</p>

                <h4 className="card-subtitle mb-2 text-muted">Collaboration and Coordination</h4>
                <p className="card-text">To maintain effective collaboration, our team conducts regular coordination meetings, uses project management tools, and maintains open communication channels. This approach ensures that all stakeholders work cohesively towards the projects successful completion.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={budget} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "600px", width: "500", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Budget and Funding</h3>
                <p className="card-text">Total Budget: $[Your Budget]</p>
                <h4 className="card-subtitle mb-2 text-muted">Total Budget</h4>
                <p className="card-text">The total budget allocated for this project is $[Your Total Budget]. This comprehensive budget covers all project expenses, ensuring the successful completion of the project within the financial constraints.</p>

                <h4 className="card-subtitle mb-2 text-muted">Funding Sources</h4>
                <p className="card-text">Funding for this project is derived from multiple sources to meet the financial requirements. The projects funding sources include:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Source 1]: Describe the first funding source and its significance.</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Source 2]: Explain the second funding source and its contribution to the project.</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>[Source 3]: Provide details about additional funding sources if applicable.</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Budget Breakdown</h4>
                <p className="card-text">The projects budget is allocated across various categories to ensure efficient resource utilization. The budget breakdown includes:</p>
                <ul style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Construction Costs: $[Construction Budget]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Labor and Personnel: $[Labor Budget]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Materials and Supplies: $[Materials Budget]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Design and Engineering Fees: $[Design and Engineering Budget]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Contingency Funds: $[Contingency Budget]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Other Expenses: $[Other Budget]</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Budget Management</h4>
                <p className="card-text">Effective budget management is crucial for the success of this project. We employ a rigorous financial reporting and oversight system to ensure that the budget is adhered to throughout the projects lifecycle. Regular audits and financial monitoring are integral to our budget management strategy.</p>
              </div>
            </div>
          </div>
        </div>

        <br></br>

        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={Design} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "700px", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Design and Engineering</h3>
                <h4 className="card-subtitle mb-2 text-muted">Design Elements</h4>
                <p className="card-text">The projects design emphasizes [Key Design Elements], including [Specific Design Features]. These elements contribute to the projects aesthetics and functionality, creating a unique and appealing environment for its intended purpose.</p>

                <h4 className="card-subtitle mb-2 text-muted">Architectural Team</h4>
                <p className="card-text">The projects design is led by [Architectural Team or Firm], a renowned and experienced group with a proven track record in delivering innovative and successful architectural projects. Their expertise and creativity are instrumental in shaping the projects design.</p>

                <h4 className="card-subtitle mb-2 text-muted">Engineering Specifications</h4>
                <p className="card-text">The engineering aspects of the project are meticulously planned and executed. Key engineering specifications include:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Structural Design: [Description of Structural Features]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Electrical Systems: [Details of Electrical Systems]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Plumbing: [Plumbing Specifications]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>HVAC: [HVAC System Details]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Other Engineering Components: [Additional Engineering Details]</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Sustainability and Environmental Considerations</h4>
                <p className="card-text">The project prioritizes sustainability and environmental responsibility. Sustainable design practices include [Sustainable Features], such as energy-efficient lighting, use of renewable materials, and compliance with [Green Building Standards or Certifications]. These efforts aim to minimize the projects environmental footprint and promote eco-friendly practices.</p>

                <h4 className="card-subtitle mb-2 text-muted">Challenges and Solutions</h4>
                <p className="card-text">During the design and engineering phase, several challenges were encountered, such as [Specific Challenges]. However, our expert team of architects and engineers developed innovative solutions, including [Solution 1], [Solution 2], to address these challenges effectively and ensure the projects success.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6" >
              <img src={timeline} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "400px", width: "640px", marginTop: "100px" }} />
            </div>
            <div className="col-md-6" >
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Project Timeline</h3>

                <h4 className="card-subtitle mb-2 text-muted">Start Date</h4>
                <p className="card-text">The project officially commenced on [Start Date], marking the beginning of this exciting journey.</p>

                <h4 className="card-subtitle mb-2 text-muted">End Date</h4>
                <p className="card-text">The anticipated completion date for this project is [End Date]. We are dedicated to delivering the project on time and within scope.</p>

                <h4 className="card-subtitle mb-2 text-muted">Project Phases</h4>
                <p className="card-text">The project is divided into distinct phases, each with its specific objectives and timelines. The key project phases include:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666", gap: '0px' }}>Planning Phase: [Start Date - End Date]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666", gap: "0" }}>Design Phase: [Start Date - End Date]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666", gap: '0px' }}>Construction Phase: [Start Date - End Date]</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666", gap: '0px' }}>Testing and Commissioning: [Start Date - End Date]</li>

                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Progress Updates</h4>
                <p className="card-text">Regular progress updates and communication will be maintained throughout the project. Stakeholders can expect periodic reports, project meetings, and online platforms for tracking and discussing project developments.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={safety} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "600px", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Safety Measures</h3>


                <h4 className="card-subtitle mb-2 text-muted">Safety Precautions</h4>
                <p className="card-text">We prioritize safety as a fundamental aspect of this project. General safety precautions will be strictly enforced on the project site, including but not limited to:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Wearing appropriate personal protective equipment (PPE)</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Following site access and restricted area guidelines</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Adhering to safety signage and procedures</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Safety Training</h4>
                <p className="card-text">All personnel involved in the project will receive comprehensive safety training and orientation. This training will cover topics such as:</p>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Safety guidelines and procedures</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Emergency response protocols</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Proper usage of safety equipment</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Emergency Response Plan</h4>
                <p className="card-text">Our project has a well-defined emergency response plan in place. In the event of an emergency, all workers will follow established procedures for evacuations, and contact information for emergency services will be readily available.</p>

                <h4 className="card-subtitle mb-2 text-muted">Protective Equipment</h4>
                <p className="card-text">To ensure safety, all workers and visitors must wear the appropriate personal protective equipment (PPE) at all times on the project site. This includes helmets, safety vests, gloves, and safety goggles as required.</p>

                <h4 className="card-subtitle mb-2 text-muted">Safety Inspections and Audits</h4>
                <p className="card-text">Regular safety inspections and audits will be conducted at various stages of the project. These assessments are designed to identify potential hazards and ensure compliance with safety standards.</p>

                <h4 className="card-subtitle mb-2 text-muted">Safety Officer</h4>
                <p className="card-text">A designated safety officer will oversee and enforce safety measures to maintain a safe working environment throughout the projects duration.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={env} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "630px", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Environmental Considerations</h3>
                <h4 className="card-subtitle mb-2 text-muted">Environmental Impact Assessment</h4>
                <p className="card-text">Our project has undergone a comprehensive environmental impact assessment to evaluate potential effects on air quality, water quality, soil quality, noise pollution, wildlife, and the surrounding ecosystem.</p>

                <h4 className="card-subtitle mb-2 text-muted">Environmental Regulations Compliance</h4>
                <p className="card-text">We are committed to complying with all relevant local, state, and national environmental regulations and standards. All necessary permits and approvals have been obtained from the appropriate authorities.</p>

                <h4 className="card-subtitle mb-2 text-muted">Waste Management and Recycling</h4>
                <p className="card-text">We prioritize responsible waste management and recycling practices. This includes minimizing waste generation and ensuring proper disposal of materials. Recycling will be a fundamental part of our waste management strategy.</p>

                <h4 className="card-subtitle mb-2 text-muted">Energy Efficiency</h4>
                <p className="card-text">Our project incorporates energy-efficient practices, such as using energy-efficient equipment, renewable energy sources, and energy-saving technologies to reduce energy consumption and minimize our carbon footprint.</p>

                <h4 className="card-subtitle mb-2 text-muted">Water Management</h4>
                <p className="card-text">Our water management strategy focuses on water conservation, stormwater management, and water quality protection. We are committed to preserving and protecting this vital resource.</p>

                <h4 className="card-subtitle mb-2 text-muted">Green Building Practices</h4>
                <p className="card-text">We are dedicated to sustainable construction practices, including the use of green building materials and technologies that reduce the environmental impact of our project.</p>

                <h4 className="card-subtitle mb-2 text-muted">Environmental Monitoring</h4>
                <p className="card-text">We will continuously monitor and assess our projects environmental impact throughout the construction and operational phases to ensure compliance with our environmental commitments.</p>

                <h4 className="card-subtitle mb-2 text-muted">Ecosystem Protection</h4>
                <p className="card-text">We take steps to protect local ecosystems, biodiversity, and wildlife in the project area. These efforts include habitat preservation and responsible environmental stewardship.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={update} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "630px", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Progress Update</h3>
                <h4 className="card-subtitle mb-2 text-muted">Project Milestones</h4>
                <ul style={{ marginTop: "3px" }}>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Design Phase Completed</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Foundation Laying in Progress</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Procurement of Building Materials</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Work Completed</h4>
                <p className="card-text">The design phase of the project has been successfully completed, and we have initiated the foundation laying process. Additionally, we have procured a significant portion of the building materials required for construction.</p>

                <h4 className="card-subtitle mb-2 text-muted">Current Status</h4>
                <p className="card-text">The project is currently on schedule, with no significant delays. The design phase was executed efficiently, and foundation laying is proceeding as planned. We are closely monitoring the project to ensure it stays on track.</p>

                <h4 className="card-subtitle mb-2 text-muted">Challenges Faced</h4>
                <p className="card-text">While we have encountered minor weather-related delays during the foundation laying phase, they have not significantly impacted the project timeline. We are working diligently to address these challenges as they arise.</p>

                <h4 className="card-subtitle mb-2 text-muted">Solutions Implemented</h4>
                <p className="card-text">To mitigate weather-related delays, we have adjusted the work schedule and increased the workforce during favorable weather conditions. This approach ensures that we can make up for any lost time and maintain our project timeline.</p>

                <h4 className="card-subtitle mb-2 text-muted">Upcoming Milestones</h4>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Commence Structural Construction</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Completion of Building Envelope</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Interior Finishing Phase</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={challenges} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "640px", marginTop: "200px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Challenges and Solutions</h3>
                <h4 className="card-subtitle mb-2 text-muted">Challenges Faced</h4>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Unforeseen Weather Delays</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Resource Shortages</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Design Modifications Required</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Solutions Implemented</h4>
                <p className="card-text">
                  <strong>1. Unforeseen Weather Delays:</strong> The project encountered unexpected weather delays during the construction phase. To address this challenge, we implemented a flexible work schedule that allows us to make up for lost time during favorable weather conditions. Additionally, we increased the workforce during periods of good weather to expedite construction.
                </p>
                <p className="card-text">
                  <strong>2. Resource Shortages:</strong> Resource shortages, particularly in terms of skilled labor, posed a challenge. To overcome this, we collaborated with local labor agencies to source additional skilled workers and provide them with training specific to the projects requirements.
                </p>
                <p className="card-text">
                  <strong>3. Design Modifications Required:</strong> Midway through the project, certain design modifications were needed to meet updated safety standards. These changes led to a temporary slowdown. We worked closely with the architect and engineering teams to expedite the approval of revised designs and resumed construction with the updated plans.
                </p>

                <h4 className="card-subtitle mb-2 text-muted">Lessons Learned</h4>
                <p className="card-text">The projects challenges underscore the importance of adaptability and contingency planning. We learned that its essential to have a flexible project schedule to accommodate unexpected delays and to maintain open communication channels with all project stakeholders for swift issue resolution. These lessons will guide our approach to future projects.</p>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div className="card mb-12"  >
          <div className="row g-0">
            <div className="col-md-6">
              <img src={quality} className="img-fluid rounded-start" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "500px", width: "630px", marginTop: "100px" }} />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "30px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Quality Control and Assurance</h3>
                <h4 className="card-subtitle mb-2 text-muted">Quality Control</h4>
                <p className="card-text">
                  Our project adheres to a stringent quality control process to ensure that every aspect of the work meets or exceeds industry and project-specific standards. This includes regular on-site inspections, in-process checks, and compliance assessments.
                </p>

                <h4 className="card-subtitle mb-2 text-muted">Quality Assurance</h4>
                <p className="card-text">
                  Quality assurance is a central focus of our project. We have established a comprehensive quality management system that encompasses meticulous planning, continuous process improvement, and the use of best practices. This ensures that the project will consistently deliver superior results.
                </p>

                <h4 className="card-subtitle mb-2 text-muted">Key Components of Quality Control and Assurance</h4>
                <ul>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Regular on-site inspections by dedicated quality control inspectors.</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Implementation of industry-specific quality management standards.</li>
                  <li style={{ fontFamily: "Montseerrat", fontSize: "18px", color: "#666666" }}>Certifications from relevant quality assurance agencies.</li>
                </ul>

                <h4 className="card-subtitle mb-2 text-muted">Measurable Metrics</h4>
                <p className="card-text">
                  Key performance indicators (KPIs) used for quality control and assurance include error rates, defect counts, and adherence to project timelines. These metrics provide a clear understanding of the projects quality and performance.
                </p>

                <h4 className="card-subtitle mb-2 text-muted">Quality Control Team</h4>
                <p className="card-text">
                  Our quality control team consists of experienced quality control managers, certified inspectors, and experts in the field. They are responsible for ensuring that every aspect of the project meets the highest quality standards.</p>
              </div>
            </div>
          </div>
        </div>



        <div className="card mb-12"  >
          <div className="row ">
          <h3 className="card-title" style={{ fontFamily: "Montseerrat", fontSize: "50px", fontWeight: "400", color: 'hsl(21, 62%, 45%)' }}>Completion Images</h3>
            <div className="col-md-4">
              <img src={img1} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
              <img src={img2} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px", }} />
              <img src={img3} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
            </div>
            <div className="col-md-4">
              <img src={img11} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
              <img src={img5} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
              <img src={img6} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
            </div>
            <div className="col-md-4">
              <img src={img7} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
              <img src={img8} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" }} />
              <img src={img12} className="card-img-top" alt="..." style={{ padding: "20px", paddingTop: "55px", height: "300px" ,width:"380px"}} />
            </div>
          </div>
        </div>





      </Card>

</div>

      
   
  );
}

export default Lernmore;